import { NextSeo } from "next-seo";
import { NextSeoProps } from "next-seo/lib/types";
import React from "react";

import { publicUrl } from "@config";
import { useRouter } from "@hooks/useRouter";
import { SUPPORTED_LOCALE_PATHS } from "@providers/TranslationProvider/context";

export interface SeoProps extends NextSeoProps {
  withHreflang?: boolean;
}

export const Seo = ({ withHreflang = false, ...props }: SeoProps) => {
  const { asPath } = useRouter();

  const cleanAsPath = asPath.replace(/\?.*$/, "");
  const languageAlternates = withHreflang
    ? SUPPORTED_LOCALE_PATHS.map(locale => ({
        href: `${publicUrl}/${locale}${cleanAsPath}`,
        hrefLang: `${locale.split("-")[0]}-${locale
          .split("-")[1]
          .toUpperCase()}`,
      }))
    : props.languageAlternates;

  return <NextSeo languageAlternates={languageAlternates} {...props} />;
};
