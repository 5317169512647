import debounce from "lodash/debounce";
import { useRouter } from "next/router";
import React, { RefObject, useEffect, useMemo, useRef } from "react";

import { BasketModal } from "@components/BasketModal";
import { useCheckoutContext } from "@hooks/providers";
import { useIsMdDown } from "@hooks/responsive";
import { useBlockBodyScroll } from "@hooks/useBlockBodyScroll";
import { useOnWindowEvent } from "@hooks/useOnWindowEvent";
import { useHeaderStore } from "@stores/useHeaderStore";
import { getTestAttribute } from "@utils";

import { CartIcon } from "./components";

import * as S from "./styles";

type NavCartProps = {
  mobileCartTriggerRef?: RefObject<HTMLInputElement>;
};

export const NavCart = ({ mobileCartTriggerRef }: NavCartProps) => {
  const { checkout } = useCheckoutContext();
  const isMdDown = useIsMdDown();
  const { asPath } = useRouter();
  const iconWrapperRef = useRef<HTMLLabelElement>(null);
  const { search, topBanner, basket, nav } = useHeaderStore();

  useBlockBodyScroll(isMdDown && basket.isActive);

  const changeHandler = (e: { type: string }, isBasketActive?: boolean) => {
    if (e.type === "mouseleave") {
      basket.toggleActive(false);
    } else if (e.type === "mouseenter") {
      basket.toggleActive(true);
    } else if (e.type === "click") {
      nav.menu.toggleActive(false);
      search.toggleActive(false);
    }
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    []
  );

  useEffect(() => {
    if (isMdDown) {
      basket.toggleActive(false);
    }
  }, [asPath]);

  useEffect(
    () => () => {
      debouncedChangeHandler.cancel();
    },
    []
  );

  useOnWindowEvent(
    "keydown",
    evt => {
      if (basket.isActive && evt.key === "Escape") {
        basket.toggleActive(false);
      }
    },
    [basket.isActive]
  );

  const handleCartClick = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    basket.toggleActive();
    debouncedChangeHandler(e, basket.isActive);
  };

  const handleCartMouse = (
    e: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    if (!isMdDown) {
      debouncedChangeHandler(e);
    }
  };

  return (
    <S.CartWrapper
      isBannerVisible={topBanner.isActive}
      isMenuVisible={basket.isActive}
    >
      <S.MobileTrigger
        id="cartIcon"
        isBannerVisible={topBanner.isActive}
        isMenuVisible={basket.isActive}
        ref={mobileCartTriggerRef}
        type="checkbox"
        {...getTestAttribute("mobileCartTrigger", "navigation")}
      />
      <S.CartIconWrapper
        as="label"
        className="cart-icon-wrapper"
        data-status={basket.isActive}
        htmlFor="cartIcon"
        inactive={search.isActive}
        isBannerVisible={topBanner.isActive}
        isMenuVisible={basket.isActive}
        onClick={handleCartClick}
        onMouseEnter={handleCartMouse}
        onMouseLeave={handleCartMouse}
        ref={iconWrapperRef}
        {...getTestAttribute("cart", "navigation")}
      >
        <CartIcon quantity={checkout?.quantity ?? 0} />
        <BasketModal isActive={basket.isActive} />
      </S.CartIconWrapper>
    </S.CartWrapper>
  );
};
