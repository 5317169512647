import { paths } from "@paths";
import { useAuthState } from "@saleor/sdk";
import dynamic from "next/dynamic";
import Link from "next/link";
import React from "react";

import { LikeIcon } from "@components/LikeIcon";
import { Logo } from "@components/Logo";
import { useIsMdUp } from "@hooks/responsive";
import { useAuthHook } from "@hooks/useAuthHook";
import { AuthFormLocationEnum } from "@stores/useAuthFormStore";
import { useHeaderStore } from "@stores/useHeaderStore";
import { theme } from "@styles";
import { getTestAttribute } from "@utils/misc";

import { NavCart } from "../NavCart";

import { ReactComponent as ProfileIcon } from "assets/icons/profile.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import * as GS from "../../styles";
import * as S from "./styles";

const Search = dynamic<Readonly<{}>>(
  () =>
    import(/* webpackChunkName: "Search.chunk" */ "@components/Search").then(
      mod => mod.Search
    ),
  { ssr: false }
);

export const MainHeader = () => {
  const { authenticated } = useAuthState();
  const { redirectToLoginPage, handleAuthSuccess } = useAuthHook();
  const isMdUp = useIsMdUp();

  const {
    basket,
    search,
    nav: { menu },
  } = useHeaderStore();

  const handleProfileIconClick = async (evt: React.MouseEvent) => {
    evt.stopPropagation();

    const location = isMdUp
      ? AuthFormLocationEnum.ORDER
      : AuthFormLocationEnum.PROFILE;

    if (authenticated) {
      handleAuthSuccess({ location });
    } else {
      redirectToLoginPage({ location });
    }
  };

  const handleFavouriteIconClick = async (evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (authenticated) {
      handleAuthSuccess({ location: AuthFormLocationEnum.FAVOURITES });
    } else {
      redirectToLoginPage({ location: AuthFormLocationEnum.FAVOURITES });
    }
  };

  const handleMenuClick = () => {
    menu.updateMenu({
      activeIndex: 0,
      activeTab: null,
    });
    menu.toggleActive();
    basket.toggleActive(false);
    search.toggleActive(false);
  };

  return (
    <S.HeaderWrapper>
      <S.MobileActionWrapper>
        <GS.IconWrapper
          isSearchActive={search.isActive}
          onClick={handleMenuClick}
          tabIndex={0}
          {...getTestAttribute("toggleButton", "navigation")}
        >
          <GS.MenuToggle isOpen={menu.isActive} />
        </GS.IconWrapper>
        <GS.IconWrapper
          isSearchActive={search.isActive}
          onClick={evt => {
            evt.stopPropagation();

            basket.toggleActive(false);
            menu.toggleActive(false);
            search.toggleActive();
          }}
          {...getTestAttribute("search", "navigation")}
        >
          <SearchIcon height={20} stroke={theme.colors.white} />
        </GS.IconWrapper>
      </S.MobileActionWrapper>

      <GS.LogoWrapper>
        <Link passHref href={paths.home}>
          <GS.LogoLink
            aria-label="Rough Trade"
            onClick={evt => evt.stopPropagation()}
            title="Rough Trade"
          >
            <Logo size="38px" />
          </GS.LogoLink>
        </Link>
      </GS.LogoWrapper>

      <S.SearchWrapper isActive={search.isActive}>
        <Search />
      </S.SearchWrapper>
      <S.DesktopActionWrapper>
        <GS.HeartIconWrapper
          onClick={handleFavouriteIconClick}
          {...getTestAttribute("account", "header-favourite-icon")}
        >
          <LikeIcon isLiked={authenticated} variant="white" />
        </GS.HeartIconWrapper>
        <GS.IconWrapper
          onClick={handleProfileIconClick}
          {...getTestAttribute("account", "header-profile-icon")}
        >
          <ProfileIcon
            fill={authenticated ? theme.colors.white : theme.colors.black}
          />
        </GS.IconWrapper>

        <NavCart />
      </S.DesktopActionWrapper>
    </S.HeaderWrapper>
  );
};

MainHeader.displayName = "MainHeader";
